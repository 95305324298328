export enum ArtistEventStatus {
  // The artist has been accepted
  ACCEPTED = "ACCEPTED",
  // The artist's application is pending review
  PENDING = "PENDING",
  // The artist's application has been rejected
  REJECTED = "REJECTED",
  // The artist is waiting for a response
  WAITING = "WAITING",
  // The artist is seeking new opportunities
  SEEKING = "SEEKING",
}
