import {
  ActionIcon,
  Badge,
  createStyles,
  MantineSize,
  Popover,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import {
  Host,
  ProgramType,
  ProjectType,
  StageAdBookingStatus,
  StageAdStatus,
  ThreadCategory,
  UserInfo,
} from "@prisma/client";
import { User } from "@supabase/supabase-js";
import {
  IconGuitarPick,
  IconMapPin,
  IconMicrophone2,
  IconX,
} from "@tabler/icons";
import DOMPurify from "dompurify";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { FaRegLaughBeam, FaTheaterMasks } from "react-icons/fa";
import { GiMagicHat, GiTeamIdea, GiTopHat } from "react-icons/gi";
import { HiOutlineLightBulb } from "react-icons/hi";
import { LuHandMetal } from "react-icons/lu";
import { TbBuildingCircus, TbGuitarPick, TbVinyl } from "react-icons/tb";
import { ThemeContext } from "../contexts/ThemeContext";
import { ArtistEventStatus } from "../ts/enums/ArtistEventStatus";
import { AdType } from "../ts/types/AdType";
import { SimpleAdType } from "../ts/types/SimpleAdType";
import { RiSparkling2Fill } from "react-icons/ri";

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function displayDomainBadge(domain: string) {
  switch (domain) {
    case "stand-up":
      return (
        <Badge
          className="bg-yellow-500 text-black"
          leftSection={<FaRegLaughBeam className="mt-1" />}
        >
          Stand Up
        </Badge>
      );
    case "improvisation":
      return (
        <Badge
          className="bg-pink-700 text-white"
          leftSection={<HiOutlineLightBulb size={16} />}
        >
          Improvisation
        </Badge>
      );
    case "magic":
      return (
        <Badge
          className="bg-blue-600 text-white"
          leftSection={<GiMagicHat className="mt-1" />}
        >
          Magie
        </Badge>
      );
    case "theatre":
      return (
        <Badge
          className="bg-orange-600 text-white"
          leftSection={<FaTheaterMasks className="mt-1" />}
        >
          Théâtre
        </Badge>
      );
    case "circus":
      return (
        <Badge
          className="bg-green-600 text-white"
          leftSection={<TbBuildingCircus className="mt-1" />}
        >
          Art du cirque
        </Badge>
      );
    case "rap":
      return (
        <Badge
          className="bg-[#c8ad7f] text-white"
          leftSection={<IconMicrophone2 className="mt-1" size={14} />}
        >
          Rap
        </Badge>
      );
    case "dance":
      return <Badge className="bg-purple-600 text-white">Danse</Badge>;
    case "music":
      return <Badge className="bg-rose-600 text-white">Musique Live</Badge>;
    case "edm":
      return (
        <Badge
          leftSection={<TbVinyl className="mt-1" size={14} />}
          className="bg-purple-700 text-white"
        >
          EDM
        </Badge>
      );
    default:
      return <Badge className="bg-indigo-500 text-black">{domain}</Badge>;
  }
}

export function DisplayDomainBadges({
  domains,
  size,
  slice,
}: {
  domains: string[];
  size: MantineSize;
  slice?: number;
}) {
  const [opened, { close, open }] = useDisclosure(false);

  const sliceValue = slice ? slice : 3;

  const badgeMap: Record<
    string,
    { className: string; leftSection?: JSX.Element }
  > = {
    "stand-up": {
      className: "bg-yellow-500 text-black",
      leftSection: <FaRegLaughBeam className="mt-1" />,
    },
    improvisation: {
      className: "bg-pink-700 text-white",
      leftSection: <HiOutlineLightBulb size={14} className="mt-[2px]" />,
    },
    magic: {
      className: "bg-blue-600 text-white",
      leftSection: <GiMagicHat className="mt-1" />,
    },
    theatre: {
      className: "bg-orange-600 text-white",
      leftSection: <FaTheaterMasks className="mt-1" />,
    },
    circus: {
      className: "bg-green-600 text-white",
      leftSection: <TbBuildingCircus className="mt-1" />,
    },
    rap: {
      className: "bg-[#c8ad7f] text-white",
      leftSection: <IconMicrophone2 className="mt-1" size={14} />,
    },
    dance: {
      className: "bg-purple-600 text-white",
    },
    music: {
      className: "bg-rose-600 text-white",
    },
    edm: {
      className: "bg-purple-700 text-white",
      leftSection: <TbVinyl className="mt-1" size={14} />,
    },
    rock: {
      className: "bg-black text-white",
      leftSection: <LuHandMetal className="mt-1" size={14} />,
    },
    pop: {
      className: "bg-purple-600 text-white",
      leftSection: <RiSparkling2Fill className="mt-1" size={14} />,
    },
  };

  function renderBadge(domain: string) {
    const badgeProps = badgeMap[domain] || {
      className: "bg-indigo-500 text-black",
    };
    return (
      <Badge
        className={badgeProps.className}
        leftSection={badgeProps.leftSection}
        size={size}
      >
        {domain === "dance"
          ? "Danse"
          : domain === "music"
          ? "Musique Live"
          : domain.toUpperCase()}
      </Badge>
    );
  }

  const badgeElements = domains.slice(0, sliceValue).map((e) => renderBadge(e));

  if (domains.length > sliceValue) {
    const remainingCount = domains.length - sliceValue;
    badgeElements.push(
      <Badge className="bg-black text-white" size={size}>
        + {remainingCount}
      </Badge>,
    );
  }

  return (
    <Popover
      styles={{ dropdown: { maxWidth: "300px" } }}
      position="bottom"
      withArrow
      shadow="md"
      opened={opened}
      zIndex={1000}
    >
      <Popover.Target>
        <div
          onMouseEnter={open}
          onMouseLeave={close}
          className="flex flex-wrap gap-2"
        >
          {badgeElements}
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <div className="flex flex-wrap gap-2 ">
          {domains.map((domain) => (
            <div key={domain}>{renderBadge(domain)}</div>
          ))}
        </div>
      </Popover.Dropdown>
    </Popover>
  );
}

export function displayArtistEventStatus(status: ArtistEventStatus) {
  switch (status) {
    case ArtistEventStatus.ACCEPTED:
      return <Badge className="bg-green-600 text-white">Accepté</Badge>;
    case ArtistEventStatus.REJECTED:
      return <Badge className="bg-red-600 text-white">Refusé</Badge>;
    case ArtistEventStatus.PENDING:
      return <Badge className="bg-orange-500 text-white">En Attente</Badge>;
    case ArtistEventStatus.SEEKING:
      return <Badge className="bg-gray-500 text-white">En Recherche</Badge>;
    case ArtistEventStatus.WAITING:
      return <Badge className="bg-purple-600 text-white">À Ajouter</Badge>;
  }
}
export function DisplayItems({
  items,
  size,
  slice,
}: {
  items: string[];
  size: MantineSize;
  slice?: number;
}) {
  const [opened, { close, open }] = useDisclosure(false);
  const sliceValue = slice ? slice : 3;

  const badgeElements = items.slice(0, sliceValue).map((e) => (
    <Badge key={e} className="bg-indigo-500 text-black" size={size}>
      {e}
    </Badge>
  ));

  if (items.length > sliceValue) {
    const remainingCount = items.length - sliceValue;
    badgeElements.push(
      <Badge className="bg-black text-white" size={size}>
        + {remainingCount}
      </Badge>,
    );
  }

  return (
    <Popover
      width={"auto"}
      position="bottom"
      withArrow
      shadow="md"
      opened={opened}
    >
      <Popover.Target>
        <div onMouseEnter={open} onMouseLeave={close} className={"flex gap-1"}>
          {badgeElements}
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <div className="flex flex-wrap gap-1">
          {items.map((item) => (
            <Badge key={item} className="bg-indigo-500 text-black" size={size}>
              {item}
            </Badge>
          ))}
        </div>
      </Popover.Dropdown>
    </Popover>
  );
}

export function displayProgramTypes(programTypes: ProgramType[]) {
  function renderBadge(programTypes: ProgramType) {
    switch (programTypes) {
      case ProgramType.OPEN_MIC:
        return <Badge className="bg-fuchsia-900 text-white">Open Mic</Badge>;
      case ProgramType.JAM_SESSION:
        return <Badge className="bg-pink-700 text-white">Jam Session</Badge>;
      case ProgramType.DJ_SET:
        return <Badge className="bg-blue-800 text-white">Dj Set</Badge>;
      case ProgramType.KARAOKE:
        return <Badge className="bg-orange-600 text-white">Karaoke</Badge>;
      default:
        return (
          <Badge className="bg-indigo-500 text-black">{programTypes}</Badge>
        );
    }
  }
  return programTypes.map((e) => renderBadge(e));
}

export function displayEventType(type: string) {
  return <Badge className="bg-black text-white">{type}</Badge>;
}

export function displayHostType(types: string[]) {
  return (
    <div className="flex gap-2">
      {types.map((e) => (
        <Badge key={e} className="bg-slate-900 text-white" radius={"md"}>
          {e}
        </Badge>
      ))}
    </div>
  );
}

export function displayEntryConditions(entryCondition: string) {
  switch (entryCondition) {
    case "free":
      return <>Entrée libre</>;
    case "free+drink":
      return <>Entrée libre, consommation obligatoire</>;
    case "paid":
      return <>Entrée payante</>;
    case "paid+drink":
      return <>Entrée payante, consommation obligatoire</>;
  }
}

export function displayEventDate(
  dateStart: Date,
  eventStart: Date,
  dateEnd: Date,
  eventEnd: Date,
) {
  if (dateStart.toDateString() === dateEnd.toDateString()) {
    return (
      "Le " +
      dateStart.getDate().toString() +
      " " +
      capitalizeFirstLetter(
        dateStart.toLocaleString("fr-FR", {
          month: "long",
        }) as string,
      ) +
      " " +
      dateStart.getFullYear().toString() +
      " de " +
      eventStart.getHours() +
      "h" +
      (eventStart.getMinutes() < 10 ? "0" : "") +
      eventStart.getMinutes().toString() +
      " à " +
      eventEnd.getHours() +
      "h" +
      (eventEnd.getMinutes() < 10 ? "0" : "") +
      eventEnd.getMinutes().toString()
    );
  } else {
    return (
      "Du " +
      dateStart.getDate().toString() +
      " " +
      capitalizeFirstLetter(
        dateStart.toLocaleString("fr-FR", {
          month: "long",
        }) as string,
      ) +
      " " +
      "au " +
      dateEnd.getDate().toString() +
      " " +
      capitalizeFirstLetter(
        dateEnd.toLocaleString("fr-FR", {
          month: "long",
        }) as string,
      ) +
      " " +
      dateStart.getFullYear().toString() +
      " de " +
      eventStart.getHours() +
      "h" +
      (eventStart.getMinutes() < 10 ? "0" : "") +
      eventStart.getMinutes().toString() +
      " à " +
      eventEnd.getHours() +
      "h" +
      (eventEnd.getMinutes() < 10 ? "0" : "") +
      eventEnd.getMinutes().toString()
    );
  }
}

export function displayTimeSlot(eventStart: Date, eventEnd: Date) {
  return (
    eventStart.getHours() +
    "h" +
    (eventStart.getMinutes() < 10 ? "0" : "") +
    eventStart.getMinutes().toString() +
    " à " +
    eventEnd.getHours() +
    "h" +
    (eventEnd.getMinutes() < 10 ? "0" : "") +
    eventEnd.getMinutes().toString()
  );
}

export function displayEventCondition(entryCondition: string) {
  switch (entryCondition) {
    case "free":
      return <>Entrée libre</>;
    case "free+drink":
      return <>Entrée libre, consommation obligatoire</>;
    case "paid":
      return <>Entrée payante</>;
    case "paid+drink":
      return <>Entrée payante, consommation obligatoire</>;
  }
}

export function updateHostFollowees(
  id: string,
  name: string,
  user: User | null,
  userInfo: UserInfo | null,
  isFollowing: boolean | undefined,
  hostFollow: any,
  hostUnfollow: any,
  getHostFollowees: any,
  setModalConnection: Dispatch<SetStateAction<boolean>> | null,
  setIsFollowing: Dispatch<SetStateAction<boolean | undefined>>,
) {
  if (user && userInfo?.uid) {
    if (!isFollowing) {
      setIsFollowing(true);

      hostFollow.mutate({
        uid: userInfo?.uid,
        followingId: id,
      });

      showNotification({
        autoClose: 3000,
        title: "Merci pour le suivi !",
        message: `Vous êtes maintenant abonné à l'activité de ${name}`,
        color: "red",
        icon: <AiFillHeart />,
        className: "my-notification-class",
        loading: false,
        radius: "md",
      });
    } else {
      setIsFollowing(false);

      getHostFollowees.refetch();

      hostUnfollow.mutate({
        uid: userInfo?.uid,
        newFollowingId: id,
        oldFollowing: getHostFollowees.data?.hostFollowees as string[],
      });

      showNotification({
        autoClose: 3000,
        title: "En espérant vous revoir !",
        message: `Vous êtes désabonné à l'activité de ${name}`,
        color: "gray",
        icon: <AiOutlineHeart />,
        className: "my-notification-class",
        loading: false,
        radius: "md",
      });
    }
  } else {
    if (setModalConnection) setModalConnection(true);
  }
}

export function updateArtistFollowees(
  id: string,
  name: string,
  user: User | null,
  userInfo: UserInfo | null,
  isFollowing: boolean | undefined,
  artistFollow: any,
  artistUnfollow: any,
  getArtistFollowees: any,
  setModalConnection: Dispatch<SetStateAction<boolean>> | null,
  setIsFollowing: Dispatch<SetStateAction<boolean | undefined>>,
) {
  if (user && userInfo?.uid) {
    if (!isFollowing) {
      setIsFollowing(true);

      artistFollow.mutate({
        uid: userInfo?.uid,
        followingId: id,
      });

      showNotification({
        autoClose: 3000,
        title: "Merci pour le suivi !",
        message: `Vous êtes maintenant abonné à l'activité de ${name}`,
        color: "red",
        icon: <AiFillHeart />,
        className: "my-notification-class",
        loading: false,
        radius: "md",
      });
    } else {
      setIsFollowing(false);

      getArtistFollowees.refetch();

      artistUnfollow.mutate({
        uid: userInfo?.uid,
        newFollowingId: id,
        oldFollowing: getArtistFollowees.data?.artistFollowees as string[],
      });

      showNotification({
        autoClose: 3000,
        title: "En espérant vous revoir !",
        message: `Vous êtes désabonné à l'activité de ${name}`,
        color: "gray",
        icon: <AiOutlineHeart />,
        className: "my-notification-class",
        loading: false,
        radius: "md",
      });
    }
  } else {
    if (setModalConnection) setModalConnection(true);
  }
}

export function displayHostAddress(host: Host) {
  return (
    <>
      <Text size={"xl"} weight={"400"} pb={3}>
        Adresse
      </Text>
      <div>{host?.name}</div>
      <div>{host?.street}</div>
      <div>
        {host?.zipcode.at(0)}, {host?.city}
      </div>
    </>
  );
}

export function displayProjectType(type: ProjectType) {
  // Check the value of the type and return the appropriate JSX element
  switch (type) {
    case ProjectType.COLLABORATION:
      return <Badge className="bg-green-600 text-white">Collaboration</Badge>;
    case ProjectType.EVENT:
      return <Badge className="bg-purple-600 text-white">Évènement</Badge>;
    case ProjectType.SHOOTING:
      return <Badge className="bg-orange-500 text-white">Tournage</Badge>;
  }
}

export function displayCardAvatarTheme(avatarTheme: string) {
  function renderBadge(avatarTheme: string) {
    switch (avatarTheme) {
      case "INDUS":
        return <Badge className="bg-indigo-500 text-black">Industriel</Badge>;
      default:
        return (
          <Badge className="bg-indigo-500 text-black">{avatarTheme}</Badge>
        );
    }
  }
  return renderBadge(avatarTheme);
}

export function dataURItoBlob(dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  //@ts-ignore
  var byteString = window.atob(dataURI.split(",")[1]);

  // separate out the mime component
  //@ts-ignore
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

export function getNthDayOfWeekOfCurrentYear(
  targetDay: string,
  targetOccurrence: number,
): Date[] {
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const targetDayIndex = dayNames.indexOf(targetDay);
  const currentYear = new Date().getFullYear();
  const result: Date[] = [];

  for (let i = 0; i < 12; i++) {
    const firstDayOfMonth = new Date(currentYear, i, 1);
    const daysUntilTargetDay =
      (targetDayIndex + 7 - firstDayOfMonth.getDay()) % 7;
    const targetDayOfMonth = new Date(
      currentYear,
      i,
      1 + daysUntilTargetDay + (targetOccurrence - 1) * 7,
    );
    result.push(targetDayOfMonth);
  }

  return result;
}

export function formatDate(value: Date | null) {
  if (!value) return "";

  const date = value.toLocaleDateString("fr-FR", {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const dateParts = date.split(" ");
  if (dateParts.length < 4) {
    throw new Error("Unexpected date format");
  }

  const day = dateParts[0];
  const month = dateParts[1];
  const dayNumber = dateParts[2];
  const year = dateParts[3];

  if (!day || !month || !dayNumber || !year) {
    throw new Error("One or more date parts are undefined");
  }

  return `${day.slice(0, 1).toUpperCase() + day.slice(1)}  ${
    month.slice(0, 1).toUpperCase() + month.slice(1)
  } ${dayNumber} ${year}`;
}

export function displayPromotionStatus(promotionStatus: string) {
  switch (promotionStatus) {
    case "USED":
      return <Badge className="bg-gray-500 text-black">UTILISÉ</Badge>;
    case "ACTIVE":
      return <Badge className="bg-green-700 text-black">ACTIF</Badge>;
    case "INACTIVE":
      return <Badge className="bg-orange-400 text-black">INACTIF</Badge>;
  }
}

export function displayPromotionType(promotionType: string | null) {
  switch (promotionType) {
    case "free-product":
      return (
        <Badge radius={"sm"} className="bg-pink-600 text-white">
          PRODUIT OFFERT
        </Badge>
      );
    case "discount":
      return (
        <Badge radius={"sm"} className="bg-pink-600 text-white">
          REMISE
        </Badge>
      );
    case "special":
      return (
        <Badge radius={"sm"} className="h-auto bg-purple-600 text-white">
          EVENEMENT <br /> SPECIAL
        </Badge>
      );
    case "other":
      return (
        <Badge radius={"sm"} className="h-auto bg-purple-600 text-white">
          AUTRE
        </Badge>
      );
    case null:
      return (
        <Badge radius={"sm"} className="bg-gray-400 text-black">
          N/A
        </Badge>
      );
  }
}

export function displayCompensationType(promotionType: string | null) {
  switch (promotionType) {
    case "hat":
      return (
        <Badge
          radius={"md"}
          className="bg-pink-600 text-white"
          leftSection={<GiTopHat className="mr-1 mt-1" size={14} />}
        >
          Rémunération au Chapeau
        </Badge>
      );
  }
}

export function displayRecruitmentType(recruitmentType: string | null) {
  switch (recruitmentType) {
    case "open-mic":
      return (
        <Badge radius={"md"} className="bg-black text-white">
          Open Mic
        </Badge>
      );
    case "event":
      return (
        <Badge radius={"md"} className="bg-black text-white">
          Evènement
        </Badge>
      );
    case "casting":
      return (
        <Badge
          radius={"md"}
          className="bg-purple-600 text-white"
          leftSection={<GiTopHat className="mr-1 mt-1" size={14} />}
        >
          Casting
        </Badge>
      );
    case "festival":
      return (
        <Badge
          radius={"md"}
          className="bg-orange-600 text-white"
          leftSection={<GiTopHat className="mr-1 mt-1" size={14} />}
        >
          Festival
        </Badge>
      );
  }
}

export function displayTown(town: string) {
  return (
    <div className={`flex text-[#facc15]`}>
      <IconMapPin size={"20"} />
      <div className="ml-1 self-end text-sm font-semibold text-white">
        {town}
      </div>
    </div>
  );
}

export function displayHostCardStackStatus(status: string) {
  switch (status) {
    case "idle":
      return (
        <Badge radius={"sm"} className="h-auto bg-violet-400 text-black">
          En Vente
        </Badge>
      );
    case "rented":
      return (
        <Badge radius={"sm"} className="bg-green-600 text-white">
          En Location
        </Badge>
      );
    case "proposal":
      return (
        <Badge radius={"sm"} className="h-auto bg-yellow-400 text-black">
          En attente <br />
          de réponse
        </Badge>
      );
    default:
      return (
        <Badge radius={"sm"} className="bg-gray-500 text-white">
          Inconnu
        </Badge>
      );
  }
}
export function displayStageAdStatus(status: StageAdStatus) {
  const statusMap: {
    [key in StageAdStatus]: { className: string; text: string };
  } = {
    DRAFT: { className: "bg-gray-400", text: "EN RECHERCHE" },
    READY: { className: "bg-purple-500", text: "PRÊT" },
    SENT: { className: "bg-lime-400", text: "ENVOYÉ" },
    ACCEPTED: { className: "bg-green-600", text: "ACCEPTÉ" },
    REJECTED: { className: "bg-red-400", text: "REFUSÉ" },
  };

  const { className, text } = statusMap[status];

  return <Badge className={`rounded-sm text-black ${className}`}>{text}</Badge>;
}

export function displayStageAdBookingStatus(
  status: StageAdBookingStatus | undefined,
) {
  switch (status) {
    case "CONFIRMED":
      return (
        <Badge className="rounded-sm bg-lime-600 text-black">CONFIRMÉ</Badge>
      );
    case "INTERESTED":
      return (
        <Badge className="rounded-sm bg-purple-400 text-black">INTÉRESSÉ</Badge>
      );
    case "ACCEPTED":
      return (
        <Badge className="rounded-sm bg-green-400 text-black">ACCEPTÉ</Badge>
      );
    case "REJECTED":
      return (
        <Badge className="rounded-sm bg-red-400 text-black">INTÉRESSÉ</Badge>
      );
    default:
      return (
        <Badge className="rounded-sm bg-gray-400 text-black">
          En Recherche
        </Badge>
      );
  }
}

export function displaySimpleAdType(status: SimpleAdType) {
  const statusMap: {
    [key in SimpleAdType]: { text: string };
  } = {
    casting: { text: "Casting" },
    collaboration: { text: "Collaboration" },
    filming: { text: "Tournage" },
    performance: { text: "Prestation" },
    training: { text: "Formateur" },
    voiceover: { text: "Doublage" },
  };

  const { text } = statusMap[status];

  return (
    <Badge color="black" className={`} rounded-md bg-black text-white`}>
      {text}
    </Badge>
  );
}

export function adjustBrightness(hexColor: string, percent: number): string {
  const color = hexColor.replace("#", "");
  const num = parseInt(color, 16);
  const r = (num >> 16) + percent;
  const g = ((num >> 8) & 0x00ff) + percent;
  const b = (num & 0x0000ff) + percent;

  const newR = Math.min(255, Math.max(0, r));
  const newG = Math.min(255, Math.max(0, g));
  const newB = Math.min(255, Math.max(0, b));

  const newColor = ((newR << 16) | (newG << 8) | newB)
    .toString(16)
    .padStart(6, "0");
  return `#${newColor}`;
}

interface DisplayThreadTypeProps {
  threadCategory: ThreadCategory | undefined;
}

export function DisplayThreadCategory({
  threadCategory,
}: DisplayThreadTypeProps) {
  const threadCategoryMapping: {
    [key in ThreadCategory]: { color: string; label: string };
  } = {
    ANNOUNCEMENT: { color: "#9B59B6", label: "ANNONCE" },
    COLLABORATION: { color: "#F06292", label: "COLLABORATION" },
    FEATURES: { color: "#d3d3d3", label: "NOUVELLES FONCTIONNALITÉS" },
    FEEDBACK: { color: "#FFC107", label: "RETOURS" },
    NEW_HOST: { color: "#33F8FF", label: "NOUVEL ÉTABLISSEMENT" },
    MEET_UP: { color: "#4CAF50", label: "RENCONTRE ARTISTES" },
    REFERENCES: { color: "#FF9800", label: "INSPIRATIONS & REFERENCES" },
    RESOURCES: { color: "#00BFFF", label: "RESSOURCES & OUTILS" },
    TIPS: { color: "#9191ff", label: "TUTORIELS & CONSEILS" },
    RECRUITMENT: { color: "#FF5733", label: "RECRUTEMENT" },
  };

  var color = "";
  if (threadCategory) {
    color = threadCategoryMapping[threadCategory].color;
  }

  const useStyle = createStyles(() => ({
    bgColor: {
      backgroundColor: color ? color : "",
    },
  }));

  const { classes } = useStyle();

  if (threadCategory && threadCategoryMapping[threadCategory]) {
    const { label } = threadCategoryMapping[threadCategory];

    return (
      <Badge className={`rounded-sm ${classes.bgColor} text-black`}>
        {label}
      </Badge>
    );
  }

  return null;
}

interface DisplayThreadCategoryFilterProps {
  threadCategory: ThreadCategory | undefined;
  setThreadCategory: Dispatch<SetStateAction<ThreadCategory | undefined>>;
}

export function DisplayThreadCategoryFilter({
  threadCategory,
  setThreadCategory,
}: DisplayThreadCategoryFilterProps) {
  const threadCategoryMapping: {
    [key in ThreadCategory]: { color: string; label: string };
  } = {
    ANNOUNCEMENT: { color: "#9B59B6", label: "ANNONCES" },
    COLLABORATION: { color: "#F06292", label: "COLLABORATIONS" },
    FEATURES: { color: "#d3d3d3", label: "NOUVELLES FONCTIONNALITÉS" },
    FEEDBACK: { color: "#FFC107", label: "RETOURS" },
    NEW_HOST: { color: "#33F8FF", label: "NOUVEAUX ÉTABLISSEMENTS" },
    MEET_UP: { color: "#4CAF50", label: "RENCONTRES ARTISTES" },
    REFERENCES: { color: "#FF9800", label: "INSPIRATIONS & RÉFERENCES" },
    RESOURCES: { color: "#00BFFF", label: "RESSOURCES & OUTILS" },
    TIPS: { color: "#9191ff", label: "TUTORIELS & CONSEILS" },
    RECRUITMENT: { color: "#FF5733", label: "RECRUTEMENT" },
  };

  var color = "";
  if (threadCategory) {
    color = threadCategoryMapping[threadCategory].color;
  }

  const useStyle = createStyles(() => ({
    bgColor: {
      backgroundColor: color ? color : "",
    },
  }));

  const { classes } = useStyle();

  if (threadCategory && threadCategoryMapping[threadCategory]) {
    const { label } = threadCategoryMapping[threadCategory];
    return (
      <Badge
        rightSection={
          <ActionIcon size="xs" variant="transparent">
            <IconX size={32} color="black" className="font-bold" />
          </ActionIcon>
        }
        onClick={() => setThreadCategory(undefined)}
        className={`${classes.bgColor} cursor-pointer rounded-sm text-black`}
        sx={{ paddingRight: 2 }}
      >
        {label}
      </Badge>
    );
  }

  return null; // Handle the case when type is undefined or not found in the mapping
}

export function DisplayAdType({ adType }: { adType: AdType }) {
  const { headerColor } = useContext(ThemeContext);

  const getAdTypeInfo = () => {
    switch (adType) {
      case "ANONYMOUS":
        return {
          imgSrc: "/map-pin/anonymous-map-pin.png",
          altText: "Annonce Anonyme",
          label: "Plateau anonyme",
        };
      case "PUBLIC":
        return {
          imgSrc: "/map-pin/map-pin.png",
          altText: "Annonce Public",
          label: "Plateau publique",
        };
      case "SIMPLE":
        return {
          imgSrc: "/recruitment/megaphone.png",
          altText: "Annonce simple",
          label: "Annonce simple",
        };
      default:
        return { imgSrc: "", altText: "", label: "" };
    }
  };

  const adTypeInfo = getAdTypeInfo();

  return (
    <div
      className="flex w-fit items-center rounded-md p-1 px-3"
      style={{ backgroundColor: headerColor as string }}
    >
      <img
        src={adTypeInfo.imgSrc}
        alt={adTypeInfo.altText}
        width={22}
        height={22}
        className="mr-2"
      />
      <span className="text-xs font-semibold">{adTypeInfo.label}</span>
    </div>
  );
}

export function scrollToElement(
  refId: string,
  offset: number = 0,
  duration: number = 500,
): void {
  const element = document.getElementById(refId);
  if (!element) return;

  const start = window.scrollY || window.pageYOffset;
  const to = element.offsetTop + offset;
  const startTime = performance.now();

  function scroll() {
    const currentTime = performance.now();
    const elapsed = currentTime - startTime;

    window.scrollTo({
      top: start + easeInOutCubic(elapsed, 0, to - start, duration),
      behavior: "smooth",
    });

    if (elapsed < duration) {
      requestAnimationFrame(scroll);
    }
  }

  function easeInOutCubic(t: number, b: number, c: number, d: number): number {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t * t + b;
    t -= 2;
    return (c / 2) * (t * t * t + 2) + b;
  }

  requestAnimationFrame(scroll);
}

export function sanitizeInput(input: string) {
  const element = document.createElement("div");
  element.innerText = input;
  return element.innerHTML;
}

export function DisplaySanitizedContent({ content }: { content: string }) {
  const sanitizedContent = DOMPurify.sanitize(content);

  return <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />;
}

export function formatTimeStamp(date: Date): string {
  let timestamp = "";

  const difference = new Date().getTime() - new Date(date).getTime();
  const minutes = Math.floor(difference / 60000);

  if (minutes < 1) {
    timestamp = "il y a un instant";
  } else if (minutes < 60) {
    timestamp = `il y a ${minutes} minute(s)`;
  } else {
    const hours = Math.round(minutes / 60);
    if (hours < 24) {
      timestamp = `il y a ${hours} heure(s)`;
    } else {
      timestamp = new Date(date).toLocaleDateString("fr-FR");
    }
  }

  return timestamp;
}

export function DisplayPartnerBadge({ size }: { size?: number }) {
  const [opened, { close, open }] = useDisclosure(false);
  return (
    <Popover
      styles={{ dropdown: { maxWidth: "240px" } }}
      position="bottom"
      withArrow
      shadow="md"
      opened={opened}
      zIndex={1000}
    >
      <Popover.Target>
        <div
          onMouseEnter={open}
          onMouseLeave={close}
          className="flex cursor-pointer items-center gap-2"
        >
          <img
            src="/labels/partner.png"
            height={size ? size : 32}
            width={size ? size : 32}
            alt="Partner Logo"
          />
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <div className="p-1">
          <div className="mb-2 flex justify-center">
            <img
              src="/labels/partner.png"
              height={70}
              width={70}
              alt="Partner Logo"
            />
          </div>
          <p className="text-center text-sm">
            Cet établissement propose des créneaux d&apos;inscriptions aux
            artistes inscrits sur Atrio
          </p>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
}

export function DisplayLargePartnerBadge() {
  const [opened, { close, open }] = useDisclosure(false);
  return (
    <Popover
      styles={{ dropdown: { maxWidth: "240px" } }}
      withArrow
      shadow="md"
      opened={opened}
      zIndex={1000}
    >
      <Popover.Target>
        <div
          onMouseEnter={open}
          onMouseLeave={close}
          className="flex cursor-pointer items-center gap-2"
        >
          <img
            src="/labels/partner.png"
            height={50}
            width={50}
            alt="Partner Logo"
          />
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <div className="p-1">
          <div className="mb-2 flex justify-center">
            <img
              src="/labels/partner.png"
              height={70}
              width={70}
              alt="Partner Logo"
            />
          </div>
          <p className="text-center text-sm">
            Ces établissements proposent des créneaux d&apos;inscriptions aux
            artistes inscrits sur Atrio
          </p>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
}

export function displayThemeTitle({
  fontType,
  title,
  sizeDelta,
}: {
  fontType: string;
  title: string;
  sizeDelta: number;
}) {
  enum FontType {
    Classic = "font-classic",
    Disco = "font-disco",
    Edm = "font-edm",
    Jazz = "font-jazz",
    Rock = "font-rock",
    Rap = "font-rap",
  }

  const baseSizes: { [key in FontType]: number } = {
    [FontType.Classic]: 8, // base size for font-classic
    [FontType.Disco]: 7, // base size for font-disco, and so on...
    [FontType.Edm]: 7,
    [FontType.Jazz]: 7,
    [FontType.Rock]: 5,
    [FontType.Rap]: 7,
  };

  const calculateSize = (font: FontType) => {
    const newSize = baseSizes[font] + sizeDelta;
    return `text-${newSize}xl`;
  };
  switch (fontType) {
    case FontType.Classic:
      return (
        <div>
          <div
            className={` ${FontType.Classic} ${calculateSize(
              FontType.Classic,
            )}`}
          >
            {title}
          </div>
        </div>
      );
    case FontType.Disco:
      return (
        <div>
          <div
            className={` ${FontType.Disco} ${calculateSize(FontType.Disco)}`}
          >
            {title}
          </div>
        </div>
      );
    case FontType.Edm:
      return (
        <div>
          <div className={` ${FontType.Edm} ${calculateSize(FontType.Edm)}`}>
            {title}
          </div>
        </div>
      );
    case FontType.Jazz:
      return (
        <div>
          <div className={` ${FontType.Jazz} ${calculateSize(FontType.Jazz)}`}>
            {title}
          </div>
        </div>
      );
    case FontType.Rock:
      return (
        <div>
          <div className={` ${FontType.Rock} ${calculateSize(FontType.Rock)}`}>
            {title}
          </div>
        </div>
      );
    case FontType.Rap:
      return (
        <div>
          <div className={` ${FontType.Rap} ${calculateSize(FontType.Rap)}`}>
            {title}
          </div>
        </div>
      );
    default:
      return (
        <div>
          <div
            className={` ${FontType.Classic} ${calculateSize(
              FontType.Classic,
            )}`}
          >
            {title}
          </div>
        </div>
      );
  }
}
